<template>
  <b-card-code>
    <form action="javascript:void(0)" ref="find" @submit="find">
      <div class="row">
        <!--classes drop down-->
        <div class="col-md-4 form-group mb-3">
          <label for="classes">Select Class</label>
          <select class="form-control" id="classes" name="classes_id" v-model="classes_id" required>
            <option :value="null">Select One</option>
            <option v-for="(cls,key) in classes" :key="key" :value="cls.id">{{ cls.name }}</option>
          </select>
        </div>
        <div class="col-md-4 form-group mb-3">
          <label for="academic_year_id">Select academic year</label>
          <select class="form-control" id="academic_year_id" v-model="academic_year_id" required name="academic_year_id">
            <option :value="null">Select One</option>
            <option v-for="(yr,key) in academic_years" :key="key" :value="yr.id">{{ yr.year }}</option>
          </select>
        </div>
        <div class="col-md-4">
          <button type="submit" class="btn btn-primary mt-2">Find</button>
        </div>
      </div>
    </form>
    <table id="table" class="table table-striped table-bordered" style="width:100%">
      <thead>
      <tr>
        <th scope="col">SL#</th>
        <th>Wing</th>
        <th>Sections</th>
        <th>Action</th>
      </tr>
      </thead>
      <tbody>
        <tr v-for="(wing,key) in show_wings" :key="key">
          <th scope="row">{{key+1}}</th>
          <td>{{wing.name}}</td>
          <td>
             <template>
            <ul>
              <li v-for="section in wing.sections" v-bind:key="section.id">{{section.name}}</li>
            </ul>
            </template>
          </td>
          <td>
            <!-- modal login button -->
            <b-button v-if="$can('update','Wings Vs. Section')" @click="selectedRow(wing)"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      v-b-modal.modal-login
                      variant="primary" >
              <feather-icon icon="Edit2Icon" class="mr-50"/>
            </b-button>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- modal login-->
    <b-modal ref="my-modal" size="lg"
             hide-footer
             title="Update wing wise sections"
    >
      <!-- form -->
      <validation-observer ref="simpleRules">
        <b-form ref="class_add" style="min-height: 300px">
          <b-row>
            <b-col md="6">
              <b-card-text>
                <span>Select Institution </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  name="wing"
                  rules="required" >
                <v-select
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="wings"
                    v-model="selected_wing"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <b-card-text>
                <span>Select sections </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  name="sections"
                  rules="required" >
                <v-select
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    multiple
                    label="name"
                    :options="sections"
                    v-model="selected_section_ids"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <!-- submit button -->
            <b-col md="12" class="mt-2">
              <b-button
                  variant="primary"
                  type="submit"
                  @click.prevent="updateWingSections"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import apiCall from "@/libs/axios";
import {mapGetters} from "vuex";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BForm, BRow, BCol, BButton,BCardText
} from 'bootstrap-vue'
import {
  required
} from '@validations'
export default {
  name:'Classes',
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BCardText
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      wings:[],
      show_wings:[],
      classes_id:null,
      academic_year_id:null,
      selected_wing:{},
      selected_section_ids:[],
      wing_section_ids:[],
    }
  },
  methods:{
    selectedRow(wing){
      this.selected_wing=wing;
      this.selected_section_ids=wing.sections;
      this.wing_section_ids=wing.sections.map(item=>item.id);
      this.$refs['my-modal'].show();
    },
    updateWingSections(){
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
            let data = new FormData();
            let sections=this.selected_section_ids.map(item=>{
              if (item.section_id) return item.section_id;
              else return item.id;
            });
            data.append('classes_id',this.classes_id);
            data.append('academic_year_id',this.academic_year_id);
            data.append('wing_id',this.selected_wing.id);
            data.append('sections',JSON.stringify(sections));
            data.append('wing_section_ids',JSON.stringify(this.wing_section_ids));
            apiCall.post('/wing/wise/section/update',data).then((response)=>{
              this.hideModal();
              this.$toaster.success(response.data.message);
              this.find();
            }).catch((error)=>{
              this.hideModal();
              this.$toaster.error(error.response.data.message);
            })
        }
      });
    },
    find(){
      apiCall.post('/get/section/wings',new FormData(this.$refs.find)).then((response)=>{
        this.wings=response.data;
      }).catch(()=>{
        this.wings=[];
      });
    },
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
  },
  computed: {
    ...mapGetters(['classes','academic_years','sections']),
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch:{
    wings(){
      this.show_wings=this.wings.filter(item=>{
        if (item.sections.length>0) return item;
      });
    }
  },
  created() {
    this.$store.dispatch('GET_ALL_ACADEMIC_YEAR');
    this.$store.dispatch('GET_ALL_CLASSES');
    this.$store.dispatch('GET_ALL_SECTION');
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';

</style>
